import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import Nav from '../Nav/Nav';

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitter
            copyright
          }
        }
      }
    `
  );
  
  return (
    <footer >
      <svg width="188" height="48" viewBox="0 0 188 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.6816 12.4053C44.7366 8.73924 41.8544 5.65379 38.3417 3.48484C32.6768 -0.00702882 25.7843 -0.935983 19.3314 0.975838C18.8669 1.13758 18.2904 1.33664 18.0499 1.89235C17.9545 2.1163 17.8799 2.48539 18.0872 2.95401C18.2531 3.32725 18.701 3.9037 19.829 3.53461C25.3779 1.73891 31.6898 2.86692 37.1557 6.62007C39.7808 8.42406 41.676 10.6594 43.4966 14.0932C45.9683 18.7587 46.4784 24.1043 44.9232 29.1514C43.6666 33.2487 41.141 36.782 37.7197 39.2911C40.0006 29.5619 33.7302 24.4568 28.6292 20.3014C25.3944 17.668 23.1757 14.7567 22.8398 12.6997C22.6325 11.439 22.732 9.63087 24.3991 8.18768C25.9833 6.81083 28.9361 6.71545 31.1755 7.08869C31.4285 7.13016 31.6607 7.19651 31.864 7.28775C31.8266 7.33752 31.7934 7.38728 31.7603 7.44119C31.1548 8.44065 31.3539 9.65161 32.2455 10.3815C33.0417 11.0326 34.4227 11.2565 35.5673 10.2032C36.5419 9.3074 36.6788 7.93885 35.8991 6.87304C35.2895 6.03532 34.319 5.66623 33.5352 5.37178L33.3984 5.31787C31.0262 4.40965 28.6333 4.11935 26.4768 4.47186C25.8921 4.56724 25.3115 4.71239 24.7516 4.90731C24.0632 5.14784 23.408 5.46717 22.7984 5.857C19.8124 7.78126 18.9332 11.58 19.3811 14.2922C19.9742 17.8919 23.0762 20.4175 26.3566 23.0883C32.0547 27.7289 37.4377 32.1124 32.8966 42.3019C32.7473 42.6336 32.7017 42.9944 32.7805 43.4423C32.8344 43.7658 32.9588 44.0478 33.1496 44.2842C33.5062 44.7238 34.0204 44.9643 34.5513 44.9643C34.7918 44.9643 35.0365 44.9145 35.2687 44.815C35.8493 44.562 36.4175 44.2883 36.9649 44.0022C42.4847 41.0785 46.5323 36.1807 48.3653 30.2172C50.2025 24.2412 49.6053 17.921 46.6816 12.4053Z" fill="#005148"/>
        <path d="M29.5789 44.4501C24.0342 46.2416 17.714 45.1178 12.2522 41.3646C9.62711 39.5606 7.73187 37.3253 5.91129 33.8915C3.43961 29.226 2.92951 23.8804 4.48468 18.8333C5.7454 14.7401 8.26685 11.2026 11.6882 8.69777C9.40731 18.4269 15.6777 23.532 20.7787 27.6874C24.0135 30.3208 26.2322 33.2321 26.5681 35.2891C26.7754 36.5498 26.6759 38.358 25.0088 39.8012C23.4204 41.1738 20.4677 41.2734 18.2324 40.9001C17.9794 40.8587 17.7472 40.7923 17.544 40.7011C17.5813 40.6513 17.6145 40.6015 17.6476 40.5476C18.2531 39.5482 18.054 38.3414 17.1624 37.6073C16.3662 36.9562 14.9893 36.7323 13.8406 37.7857C12.8702 38.6814 12.7333 40.05 13.5088 41.1199C14.1184 41.9577 15.093 42.3267 15.8768 42.6212L16.0137 42.6751C18.3858 43.5833 20.7829 43.8778 22.9352 43.5211C23.5241 43.4257 24.1047 43.2764 24.6604 43.0857C25.3488 42.8451 26.0041 42.5258 26.6137 42.136C29.5996 40.2117 30.4788 36.413 30.0309 33.7007C29.4379 30.101 26.3358 27.5754 23.0555 24.9047L22.5661 25.5019L23.0513 24.9047C17.3532 20.2599 11.9702 15.8764 16.5113 5.68696C16.6565 5.35519 16.7062 4.99439 16.6274 4.55065C16.5735 4.22303 16.4491 3.94102 16.2583 3.70879C15.74 3.06598 14.8857 2.85448 14.135 3.17796C13.5627 3.42678 12.9904 3.70049 12.443 3.99079C6.92733 6.91451 2.87974 11.8123 1.04257 17.78C-0.790454 23.7477 -0.193269 30.0679 2.73045 35.5794C4.67545 39.2454 7.55769 42.3267 11.0703 44.4998C14.811 46.8098 19.0908 48 23.3872 48C25.5894 48 27.7998 47.689 29.9397 47.0545L30.0724 47.013C30.5369 46.8513 31.1175 46.6522 31.358 46.0965C31.4534 45.8725 31.528 45.5034 31.3207 45.0348C31.1589 44.6574 30.7111 44.0851 29.5789 44.4501Z" fill="#005148"/>
        <path d="M74.2218 15.688C73.6578 12.9032 71.3665 9.30769 65.4797 9.30769C60.8266 9.30769 56.9843 12.7622 56.9843 16.957C56.9843 20.6936 59.5223 23.1964 63.3294 23.9719L66.8544 24.7122C69.0047 25.1704 70.2032 26.4747 70.2032 28.0962C70.2032 30.035 68.6522 31.5508 65.6559 31.5508C62.0604 31.5508 60.1921 29.1537 59.9453 26.5452L55.9973 27.6732C56.385 31.2687 59.3108 35.3578 65.6559 35.3578C71.3665 35.3578 74.5038 31.6565 74.5038 27.7789C74.5038 24.2539 72.142 21.4691 67.7357 20.5878L64.1401 19.8476C62.2014 19.4246 61.2143 18.2261 61.2143 16.6398C61.2143 14.7363 62.9769 13.0442 65.5149 13.0442C68.8637 13.0442 70.168 15.3355 70.4147 16.8865L74.2218 15.688Z" fill="#19224D"/>
        <path d="M77.3839 30.176C77.3839 32.8903 79.6046 35.3578 83.2002 35.3578C85.985 35.3578 87.677 33.9478 88.4878 32.6435C88.4878 34.0183 88.6288 34.7233 88.664 34.829H92.4711C92.4358 34.6528 92.2596 33.6305 92.2596 32.0795V23.5489C92.2596 20.1296 90.2503 17.1333 84.9627 17.1333C80.7327 17.1333 78.0536 19.7771 77.7364 22.7381L81.4729 23.5841C81.6492 21.8569 82.8477 20.4821 84.998 20.4821C87.254 20.4821 88.241 21.6454 88.241 23.0906C88.241 23.6899 87.959 24.1834 86.972 24.3244L82.5657 24.9942C79.6399 25.4172 77.3839 27.1092 77.3839 30.176ZM83.9757 32.15C82.3542 32.15 81.4729 31.0925 81.4729 29.9292C81.4729 28.5192 82.4952 27.8142 83.7642 27.6027L88.241 26.9329V27.7084C88.241 31.0572 86.267 32.15 83.9757 32.15Z" fill="#19224D"/>
        <path d="M102.007 41.7734L112.934 17.6621H108.598L103.981 28.5192L99.0456 17.6621H94.4277L101.76 32.7845L97.6355 41.7734H102.007Z" fill="#19224D"/>
        <path d="M114.138 30.176C114.35 32.1148 116.253 35.3578 121.224 35.3578C125.595 35.3578 127.71 32.4673 127.71 29.8587C127.71 27.3207 126.018 25.3467 122.775 24.6417L120.166 24.1129C119.109 23.9014 118.439 23.2316 118.439 22.3151C118.439 21.2576 119.461 20.3411 120.906 20.3411C123.198 20.3411 123.938 21.9274 124.079 22.9144L127.533 21.9274C127.251 20.2353 125.7 17.1333 120.906 17.1333C117.346 17.1333 114.597 19.6713 114.597 22.6676C114.597 25.0294 116.183 27.0034 119.214 27.6732L121.752 28.2372C123.127 28.5192 123.762 29.2242 123.762 30.1407C123.762 31.1982 122.88 32.1148 121.188 32.1148C119.003 32.1148 117.84 30.74 117.699 29.189L114.138 30.176Z" fill="#19224D"/>
        <path d="M147.804 34.829V30.8105H136.347V9.83644H132.117V34.829H147.804Z" fill="#19224D"/>
        <path d="M155.371 34.829V17.6621H151.318V34.829H155.371ZM150.683 11.6342C150.683 13.1147 151.882 14.278 153.327 14.278C154.807 14.278 156.006 13.1147 156.006 11.6342C156.006 10.1537 154.807 8.95518 153.327 8.95518C151.882 8.95518 150.683 10.1537 150.683 11.6342Z" fill="#19224D"/>
        <path d="M168.261 12.7975C169.036 12.7975 169.495 12.9032 169.741 12.9737V9.48394C169.459 9.34294 168.613 9.13143 167.591 9.13143C164.066 9.13143 161.598 11.458 161.598 15.3003V17.6621H158.602V21.2224H161.598V34.829H165.723V21.2224H169.706V17.6621H165.723V15.406C165.723 13.291 167.168 12.7975 168.261 12.7975Z" fill="#19224D"/>
        <path d="M175.309 24.3596C175.45 22.5266 176.931 20.5526 179.504 20.5526C182.324 20.5526 183.664 22.3504 183.734 24.3596H175.309ZM184.193 28.8365C183.629 30.5285 182.36 31.8328 179.892 31.8328C177.354 31.8328 175.309 29.9645 175.204 27.3559H187.753C187.788 27.2854 187.859 26.7214 187.859 26.0517C187.859 20.6231 184.757 17.1333 179.469 17.1333C175.133 17.1333 171.115 20.7288 171.115 26.1574C171.115 31.9738 175.239 35.3578 179.892 35.3578C184.016 35.3578 186.731 32.9255 187.647 29.9292L184.193 28.8365Z" fill="#19224D"/>
      </svg>
      <Nav type='footer'/>
      <div className="copyright">
        {site.siteMetadata.copyright}
      </div>
    </footer>
  )
}

export default Footer
