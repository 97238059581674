import React, { useEffect, useState } from "react";


interface Props {
  file:{
    url:string;
  };
  title:string;
  onLoad?:() => void ;
}
const Image: React.FC<Props> = function Comp(props) {
  
    const { title, file } = props;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      if(props.onLoad) {
        props.onLoad();
      }
      console.log(loaded)
    }, [loaded]);

  return (
    <>
    <img
        className={`image ${loaded ? 'loaded' : ''}`}
        alt={title}
        src={file?.url}
        onLoad={() => setLoaded(true)}
      />
    </>
  );

 
}

export default Image
