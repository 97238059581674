import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faPinterest, faLinkedin } from "@fortawesome/free-brands-svg-icons";

interface Props {
  navClass?:string;
  type?:string;
}

const Nav: React.FC<Props> = (props) => {

  const { type } = props;

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulNav {
          edges {
            node {
              slug
              navBtn {
                link {
                  link
                }
                title
                target
              }
            }
          }
        }
      }
    `);
    
  const socialNav = data.allContentfulNav.edges.filter(navItem => navItem.node.slug === 'social');
  const mainNav = data.allContentfulNav.edges.filter(mainNavBtn => mainNavBtn.node.slug === 'main');
  const footerNav = data.allContentfulNav.edges.filter(mainNavBtn => mainNavBtn.node.slug === 'footer');
  

  return (
    <>
    
    <nav className={props.navClass}>
      {type==='footer' ? footerNav.map((item,index) => 
        <div className={item.node.slug} key={index}>
          { 
              item.node.navBtn.map((btn, key) =>
              <a href={btn.link.link} target={btn.target} key={key}>
                {btn.title}
              </a>)
          }
        </div>
      ) : 
      mainNav.map((item,index) => 
        <div className={item.node.slug} key={index}>
          { 
              item.node.navBtn.map((btn, key) =>
              <a href={btn.link.link} target={btn.target} key={key}>
                {btn.title}
              </a>)
          }
        </div>
      )}
      {socialNav.map((item,index) => 
        <div className={item.node.slug} key={index}>
          { 
              item.node.navBtn.map((btn, key) =>
              <a href={btn.link.link} target={btn.target} key={key}>
                { btn.title === 'Facebook' && <FontAwesomeIcon icon={faFacebook}/> }
                { btn.title === 'Twitter' && <FontAwesomeIcon icon={faTwitter}/> }
                { btn.title === 'Instagram' && <FontAwesomeIcon icon={faInstagram}/> }
                { btn.title === 'Pinterest' && <FontAwesomeIcon icon={faPinterest}/> }
                { btn.title === 'LinkedIN' && <FontAwesomeIcon icon={faLinkedin}/> }
              </a>)
          }
        </div>
      )}
      
    </nav>
    </>
  )
}

export default Nav
