import React, { useEffect, useState } from "react";
import Helmet  from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
interface Props {
  data: {
    headline:string;
    description:string;
    image:{
      title:string;
      file: {
        url:string;
      };
    };
    metaTitle:string;
    metaDesc:string;
    metaImage:any;
    articleTitle:string;
  };
}

const Seo: React.FC<Props> = function Comp(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitter
            image
            favicon
            favicon16
            favicon32
            appleTouch
          }
        }
      }
    `
  )

  const [metaUrl, setMetaUrl] = useState('');

  useEffect(() => {
    setMetaUrl(window?.location.href);
  }, []);

  const metaDesc = props.data.metaDesc || site.siteMetadata.description;
  const defaultMetaTitle = site.siteMetadata?.title;
  let metaTitle = defaultMetaTitle;
  if(props.data.metaTitle) {
    metaTitle = `${metaTitle} | ${props.data.metaTitle}`;  
  }

  if(props.data.articleTitle) {
    metaTitle = `${metaTitle} | ${props.data.articleTitle}`;  
  }
  
  let metaImage = `${metaUrl}${site.siteMetadata?.image}`;
  if(props.data.metaImage) {
    metaImage = props.data.metaImage.file.url;
  }
  //console.log(`metaImage ${metaImage}`);
  //console.dir(metaImage);
  
  const metaType = 'website' || site.siteMetadata?.type; 
  const twitter = '' || site.siteMetadata?.twitter; 

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href={metaUrl} />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDesc}/>

      <meta property="og:url" content={metaUrl} />
      <meta property="og:type" content={metaType} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDesc} />
      <meta property="og:image" content={metaImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitter} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDesc} />
      <meta name="twitter:image" content={metaImage} />

      <link rel="shortcut icon" href={site.siteMetadata.favicon}/>
      <link rel="apple-touch-icon" sizes="180x180" href={site.siteMetadata.appleTouch}/>
      <link rel="icon" type="image/png" sizes="32x32" href={site.siteMetadata.favicon32}/>
      <link rel="icon" type="image/png" sizes="16x16" href={site.siteMetadata.favicon16}/>
      <meta name="msapplication-TileColor" content="#da532c"/>
      <meta name="theme-color" content="#ffffff"/>

    </Helmet>
    </>
    
  )
}


export default Seo
